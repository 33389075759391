<template>
  <div class="imageUploader">
    <div v-show="photoIdImageFile">
      <div
        class="imagePreviewWrapper"
        :style="{ 'background-image': `url(${previewImage})` }"
        @click="selectImage">
      </div>
      <b-button variant="outline-secondary" @click="clearFile">Change</b-button>
    </div>
    <div v-show="!photoIdImageFile">
      <b-form-file
        ref="fileInput"
        accept="image/png, image/jpeg, image/gif"
        v-model="photoIdImageFile"
        placeholder="Add image..."
        drop-placeholder="Drop file here..."
        @input="pickFile"
        capture
        size="lg"
      ></b-form-file>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageUploader',
  data() {
      return {
        photoIdImageFile: null,
        previewImage: null
      };
    },
  methods: {
    selectImage () {
      // this.$refs.fileInput.click()
    },
    pickFile () {
      let input = this.$refs.fileInput
      let file = input.files
      if (file && file[0]) {
        // show preview of image file
        let reader = new FileReader
        reader.onload = e => {
          this.previewImage = e.target.result
        }
        reader.readAsDataURL(file[0])

        // emit newly picked file
        this.$emit('input', file[0])
      }
    },
    clearFile () {
      this.photoIdImageFile=null
      this.$emit('input', null)
    },
  },
  mounted () {
    console.log('ImageUploader.mounted')
  }
}
</script>

<style scoped>
.imageUploader {
  text-align: center;
}
.imagePreviewWrapper {
  display: block;
  width: 250px;
  height: 250px;
  margin: 10px auto;
  background-size: cover;
  background-position: center center;
}
</style>
