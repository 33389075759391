































































































































































































































































































































































































































































































































































































































import {mapGetters, mapMutations, mapActions} from 'vuex'
import StepProgress from 'vue-step-progress'
import 'vue-step-progress/dist/main.css';
import ImageUploader from '@/components/ImageUploader.vue'

export default ({
  name: 'LeaseholderID',
  components: {
    ImageUploader,
    StepProgress
  },
  data () {
    return {
      stepNames: ['Lease Holder Info', 'Photo ID', 'Badge Photo', 'Additional Residents'],
      page: 0,
      stagedPersonId: null,
      form: {
        'site_company_id':      {value: '', touched: false, required: true,  valid: null},
        'first_name':           {value: '', touched: false, required: true,  valid: null},
        'last_name':            {value: '', touched: false, required: true,  valid: null},
        'middle_name':          {value: '', touched: false, required: false, valid: null},
        'address':              {value: '', touched: false, required: true,  valid: null},
        'building':             {value: '', touched: false, required: false, valid: null},
        'unit_num':             {value: '', touched: false, required: true,  valid: null},
        'id_num':               {value: '', touched: false, required: true,  valid: null},
        'phone':                {value: '', touched: false, required: true,  valid: null},
        'email':                {value: '', touched: false, required: false, valid: null},
        'dob':                  {value: '', touched: false, required: true,  valid: null},
        'contact_1_first_name': {value: '', touched: false, required: false, valid: null},
        'contact_1_last_name':  {value: '', touched: false, required: false, valid: null},
        'contact_1_phone':      {value: '', touched: false, required: false, valid: null},
        'contact_2_first_name': {value: '', touched: false, required: false, valid: null},
        'contact_2_last_name':  {value: '', touched: false, required: false, valid: null},
        'contact_2_phone':      {value: '', touched: false, required: false, valid: null},
      },
      idImageFile: null,
      badgePhotoFile: null,
      additionalTenantTemplate: {
        'first_name':           {value: '', touched: false, required: true,  valid: null},
        'last_name':            {value: '', touched: false, required: true,  valid: null},
        'middle_name':          {value: '', touched: false, required: false, valid: null},
        'address':              {value: '', touched: false, required: false, valid: null},
        'building':             {value: '', touched: false, required: false, valid: null},
        'unit_num':             {value: '', touched: false, required: false, valid: null},
        'id_num':               {value: '', touched: false, required: false, valid: null},
        'phone':                {value: '', touched: false, required: false, valid: null},
        'email':                {value: '', touched: false, required: false, valid: null},
        'dob':                  {value: '', touched: false, required: true,  valid: null},
      },
      additionalTenants: [],
      notNumbersPattern: /[^0-9]/g,
      minDob:   null,
      maxDobLH: null,
      maxDobT:  null
    }
  },
  computed: {
    ...mapGetters([
      'currentSite',
      'currentSiteRandId',
      'currentSiteName'
    ]),
    currentStep () {
      return (this.page - 1)
    },
    formValues () {
      const values = {}
      for (let field in this.form) {
        values[field] = this.form[field].value
      }
      return values
    }
  },
  methods: {
    ...mapActions([
      'loadSiteByRandId'
    ]),

    backToSiteMenu () {
      this.$router.push('/sites/' + this.currentSiteRandId)
    },

    showForm1 () {
      this.page = 1
    },

    parsePhoneNumber (phone, event) {
      console.log('parsePhoneNumber')
      if (typeof phone.value === 'number') {
        phone.value = phone.value.toString()
      }
      let value: string = phone.value.replaceAll(this.notNumbersPattern, '')
      if (value.charAt(0) === '1') {
        value = value.substr(1)
      }
      let length = value.length
      if (length<3) return;
      let areaCode = '', exchange = '', subscriber = '', extension = ''
      if (length>0) {
        areaCode = value.substr(0,3)
      }
      if (length>3) {
        exchange = value.substr(3,3)
      }
      if (length>6) {
        subscriber = value.substr(6,4)
      }
      if (length>10) {
        extension = value.substr(10)
      }
      if (extension) {
        value = `(${areaCode}) ${exchange}-${subscriber} x${extension}`
      } else if (subscriber) {
        value = `(${areaCode}) ${exchange}-${subscriber}`
      } else if (exchange) {
        value = `(${areaCode}) ${exchange}`
      }
      this.$set(phone, 'value', value)
      event.target.focus();
      event.target.setSelectionRange(value.length, value.length);
    },

    validateForm1 (fieldChanged: string) {
      console.log('validateForm1', fieldChanged)
      let dob, allValid = true
      if (fieldChanged) {
        this.form[fieldChanged].touched = true
      } else {
        for (let field in this.form) {
          this.form[field].touched = true
        }
      }
      for (let field in this.form) {
        if (this.form[field].required) {
          if (this.form[field].touched) {
            if (this.form[field].value) {
              this.form[field].valid = true
            } else {
              this.form[field].valid = false
              allValid = false
            }
          }
        }
        if (field === 'dob') {
          if (this.form[field].value) {
            dob = new Date(this.form[field].value);
            if (dob.toString() === 'Invalid Date') {
              this.form[field].valid = false
            } else if (dob.valueOf() < this.minDob || dob.valueOf() > this.maxDobLH) {
              this.form[field].valid = false
            } else {
              this.form[field].valid = true
            }
          } else {
            this.form[field].valid = false
            allValid = false
          }
        }
      }
      return allValid
    },
    async submitForm1 () {
      console.log('LeaseholderID.submitForm1', this.form, this.currentSite)
      this.form.site_company_id.value = this.currentSite.id
      if (!this.form.site_company_id.value) {
        console.warn('Could not submit form. Missing site ID.')
      }
      let allValid = this.validateForm1()
      if (allValid) {
        console.log('Submitting...', this.formValues)
        this.stagedPersonId = await this.$store.dispatch('submitLeaseholderIdForm', this.formValues)
        if (this.stagedPersonId) {
          window.scrollTo(0,0);
          this.page = 2
        }
      } else {
        console.warn('Could not submit form. Not all fields valid.')
      }
    },

    backAPage () {
      this.page = this.page - 1
    },

    onIdSelection (file) {
      console.log('onIdSelection', file)
      this.idImageFile = (file) ? file : null
    },
    async submitID () {
      console.log('submitID', this.idImageFile)
      const uploaded = await this.$store.dispatch('uploadStagingIdImage', this.idImageFile)
      if (uploaded) {
        this.$refs.idImageUploader.clearFile()
        window.scrollTo(0,0);
        this.page = 3
      }
    },

    onBadgePhotoSelection (file) {
      console.log('onBadgePhotoSelection', file)
      this.badgePhotoFile = (file) ? file : null
    },
    async submitBadgePhoto () {
      console.log('submitBadgePhoto', this.badgePhotoFile)
      const uploaded = this.$store.dispatch('uploadStagingBadgePhoto', this.badgePhotoFile)
      if (uploaded) {
        this.$refs.badgePhotoUploader.clearFile()
        window.scrollTo(0,0);
        this.page = 4
      }
    },

    addTenant () {
      const tenant = JSON.parse(JSON.stringify(this.additionalTenantTemplate))
      tenant.last_name.value = this.form.last_name.value
      tenant.address.value   = this.form.address.value
      tenant.building.value  = this.form.building.value
      tenant.unit_num.value  = this.form.unit_num.value
      this.additionalTenants.push(tenant)
    },
    removeTenant (atIndex: number) {
      this.additionalTenants.splice(atIndex, 1)
    },
    validateAdditionalTenants (fieldChanged: string) {
      console.log('validateAdditionalTenants', this.additionalTenants)
      let allValid: boolean = true, field: string, t: number, tenant: any, dob: Date
      for (t=0; t<this.additionalTenants.length; t++) {
        tenant = this.additionalTenants[t]
        for (field in tenant) {
          tenant[field].touched = true
          if (tenant[field].required) {
            if (tenant[field].touched) {
              if (tenant[field].value) {
                tenant[field].valid = true
              } else {
                tenant[field].valid = false
                allValid = false
              }
            }
          }
          if (field === 'dob') {
            if (tenant[field].value) {
              dob = new Date(tenant[field].value);
              if (dob.toString() === 'Invalid Date') {
                tenant[field].valid = false
              } else if (dob.valueOf() < this.minDob || dob.valueOf() > this.maxDobT) {
                tenant[field].valid = false
              } else {
                tenant[field].valid = true
              }
            } else {
              tenant[field].valid = false
              allValid = false
            }
          }
        }
      }
      return allValid
    },
    async submitAdditionalTenants () {
      console.log('LeaseholderID.submitAdditionalTenants')
      if (!this.form.site_company_id.value) {
        console.warn('Could not submit form. Missing site ID.')
      }
      let allValid = this.validateAdditionalTenants()
      if (allValid) {
        // Compile form values
        const formValues = []
        let t: number, tenant: any, tenantValues: any
        for (t=0; t<this.additionalTenants.length; t++) {
          tenant = this.additionalTenants[t]
          tenantValues = {}
          for (let field in tenant) {
            tenantValues[field] = tenant[field].value
          }
          formValues.push(tenantValues)
        }

        // Submit form values
        console.log('Submitting...', formValues)
        const updated = await this.$store.dispatch('submitAdditionalTenants', formValues)
        if (updated) {
          window.scrollTo(0,0);
          this.page = 5
        }
      } else {
        console.warn('Could not submit form. Not all fields valid.')
      }
    },

  },
  beforeMount () {
    console.log('SiteMenu.beforeMount', this.$route.params.siteRandId)
    this.loadSiteByRandId(this.$route.params.siteRandId)
  },
  mounted () {
    console.log('LeaseholderID.mounted')
    const now = new Date()
    this.minDob   = now.valueOf() - (31557600000*120) // 120 years
    this.maxDobLH = now.valueOf() - (31557600000*16)  //  16 years
    this.maxDobT  = now.valueOf() - (31557600000*12)  //  12 years
  },
  watch: {
    currentSite (newSite) {
      if (newSite) {
        this.form.site_company_id.value = newSite.id
      }
    }
  }
})
